import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link, Playground } from "docz";
import { ThemeProvider } from "styled-components";
import { Grid } from "../grid/Grid";
import { theme } from "./theme";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "responsive"
    }}>{`Responsive`}</h1>
    <p>{`All Styled component props are responsive by default. This means that for every prop, you can also pass a responsive style object instead. This allows you to apply different styles depending on the device screen width.`}</p>
    <h2 {...{
      "id": "screens"
    }}>{`Screens`}</h2>
    <div>
  Responsive props rely on the theme's `screens` key. It is therefore required, that you have{" "}
  <Link to="/styled/getting-started#theming" mdxType="Link">added a theme provider to your app</Link>. For a list of default screens,
  refer to the <Link to="/styled/utils#theme" mdxType="Link">default theme export</Link>. Note: the following examples will use the
  default theme.
    </div>
    <h2 {...{
      "id": "responsive-style-object"
    }}>{`Responsive style object`}</h2>
    <p>{`A responsive style object will take any of the theme's `}<inlineCode parentName="p">{`screens`}</inlineCode>{` as a key and any type of value as defined by the base prop. In addition to the screen keys, the interface accepts a `}<inlineCode parentName="p">{`initial`}</inlineCode>{` key to define the default styles.`}</p>
    <p>{`Given following style prop`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const styleProp = { textAlign: "center" };
`}</code></pre>
    <p>{`and assuming the default theme is available, a responsive prop may look like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const responsiveStyleProp = {
  textAlign: {
    initial: "center",
    md: "left",
    lg: "right",
  },
};
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`Change the `}<inlineCode parentName="p">{`columns`}</inlineCode>{` value and resize your browser to see the different styles based on the viewport width.`}</p>
    <Playground __position={1} __code={'<ThemeProvider theme={theme}>\n  <Grid gap={10} columns={{ initial: 1, md: 2, lg: 4 }}>\n    <Grid.Item style={{ background: \'darkgray\', padding: 20 }} />\n    <Grid.Item style={{ background: \'darkgray\', padding: 20 }} />\n    <Grid.Item style={{ background: \'darkgray\', padding: 20 }} />\n    <Grid.Item style={{ background: \'darkgray\', padding: 20 }} />\n  </Grid>\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Link,
      Playground,
      ThemeProvider,
      Grid,
      theme,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeProvider theme={theme} mdxType="ThemeProvider">
    <Grid gap={10} columns={{
          initial: 1,
          md: 2,
          lg: 4
        }} mdxType="Grid">
      <Grid.Item style={{
            background: "darkgray",
            padding: 20
          }} />
      <Grid.Item style={{
            background: "darkgray",
            padding: 20
          }} />
      <Grid.Item style={{
            background: "darkgray",
            padding: 20
          }} />
      <Grid.Item style={{
            background: "darkgray",
            padding: 20
          }} />
    </Grid>
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      